<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <ca-header
      :loading="loading"
      :heading="`Schaden ${damageReport ? (damageReport.SCHADEN.SNR || '') : 'Lade...'}`"
    >
      <template #buttonSpace>
        <router-link
          class="btn btn-secondary text-primary mr-2"
          tag="button"
          :to="{name: 'DamageReportEdit', params: {reportId: damageReport._id}}"
        >
          <i class="far fa-pen" /> Bearbeiten
        </router-link>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <div
        v-if="!loading"
        class="row"
      >
        <div class="col-12 col-xl-8 col-xxl-6">
          <ca-card class="mb-3">
            <template #header>
              <h4 class="mb-0">
                Schadensdaten
              </h4>
            </template>
            <template #body>
              <h5>Halterinformationen</h5>
              <div class="row mb-3">
                <div class="col-12 col-md-4">
                  Halter
                </div>
                <div class="col-12 col-md-8">
                  {{ order.owner.firstname }} {{ order.owner.lastname }}
                </div>
                <div class="col-12 col-md-4">
                  Fahrzeughersteller
                </div>
                <div class="col-12 col-md-8">
                  {{ order.car.producer }}
                </div>
                <div class="col-12 col-md-4">
                  Kennzeichen
                </div>
                <div class="col-12 col-md-8">
                  {{ order.car.licensePlate ? order.car.licensePlate : '-' }}
                </div>
              </div>
              <h5>Schaden</h5>
              <div class="row mb-3">
                <div class="col-12 col-md-4">
                  Schadenart
                </div>
                <div class="col-12 col-md-8">
                  {{ damageType[damageReport.SCHADEN.SAR].human }}, {{ damageReport.SCHADEN.SAR2 ? damageType[damageReport.SCHADEN.SAR2].human : '-' }}
                </div>
                <div class="col-12 col-md-4">
                  Schadentag
                </div>
                <div class="col-12 col-md-8">
                  {{ formatDate(damageReport.SCHADEN.SDA, 'DD.MM.YYYY HH:mm') }}
                </div>
                <div class="col-12 col-md-4">
                  KM bei Garantieabschluss
                </div>
                <div class="col-12 col-md-8">
                  {{ order.car.km }} km
                </div>
                <div class="col-12 col-md-4">
                  KM bei Schadenmeldung
                </div>
                <div class="col-12 col-md-8">
                  {{ damageReport.SCHADEN.KMS_SMELD }} km
                </div>
                <div class="col-12 col-md-4">
                  KM Differenz
                </div>
                <div class="col-12 col-md-8">
                  {{ damageReport.SCHADEN.KMS_SMELD - order.car.km }} km
                </div>
                <template v-if="workshop">
                  <div class="col-12 col-md-4">
                    Werkstatt
                  </div>
                  <div class="col-12 col-md-8">
                    {{ workshop.address.NAME.NAM }}
                  </div>
                </template>
                <div class="col-12 col-md-4">
                  Gemeldet am
                </div>
                <div class="col-12 col-md-8">
                  {{ formatDate(damageReport.SCHADEN.MEL, 'DD.MM.YYYY') }}
                </div>
                <div class="col-12 col-md-4">
                  Gemeldet von
                </div>
                <div class="col-12 col-md-8">
                  {{ damageReport.SCHADEN.VON }}
                </div>
                <div class="col-12 col-md-4">
                  Kontaktart
                </div>
                <div class="col-12 col-md-8">
                  {{ contactType[damageReport.SCHADEN.contactType].human }}
                </div>
              </div>
              <template v-if="damageReport.SCHADEN.ERLEDIGT">
                <h5>Status</h5>
                <div class="row mb-3">
                  <div class="col-12 col-md-4">
                    Erledigt am
                  </div>
                  <div class="col-12 col-md-8">
                    {{ formatDate(damageReport.SCHADEN.ERLEDIGT, 'DD.MM.YYYY') }}
                  </div>
                  <div class="col-12 col-md-4">
                    Bezahlt am
                  </div>
                  <div class="col-12 col-md-8">
                    {{ formatDate(damageReport.SCHADEN.BEZAHLTA, 'DD.MM.YYYY') }}
                  </div>
                  <div class="col-12 col-md-4">
                    Status
                  </div>
                  <div class="col-12 col-md-8">
                    {{ damageStatus[damageReport.SCHADEN.STATUS].human }}
                  </div>
                  <div class="col-12 col-md-4">
                    Gesendet an
                  </div>
                  <div class="col-12 col-md-8">
                    {{ damageSendTo[damageReport.SCHADEN.SENDENAN].human }}
                  </div>
                </div>
              </template>
            </template>
          </ca-card>
          <ca-card class="mb-3">
            <template #header>
              <h4 class="mb-0">
                Hergang und Notizen
              </h4>
            </template>
            <template #body>
              <div class="row mb-3">
                <div class="col-12 col-md-4">
                  Schadenhergang
                </div>
                <div class="col-12 col-md-8">
                  <div
                    class="bg-gray-1 rounded p-3 mb-2"
                  >
                    <p v-html="damageReport.INFO.BE1" />
                  </div>
                </div>
                <template v-if="damageReport.INFO.BE_INTERN">
                  <div class="col-12 col-md-4">
                    Interne Bermerkung
                  </div>
                  <div class="col-12 col-md-8">
                    <div
                      class="bg-gray-1 rounded p-3 mb-2"
                    >
                      <p v-html="damageReport.INFO.BE_INTERN" />
                    </div>
                  </div>
                </template>
                <template v-if="damageReport.INFO.SCHADEN_NOTIZ">
                  <div class="col-12 col-md-4">
                    Weitere Notizen
                  </div>
                  <div class="col-12 col-md-8">
                    <div
                      class="bg-gray-1 rounded p-3 mb-2"
                    >
                      <p v-html="damageReport.INFO.SCHADEN_NOTIZ" />
                    </div>
                  </div>
                </template>
                <template v-if="damageReport.INFO.AST_SSD">
                  <div class="col-12 col-md-4">
                    Schaden-Service
                  </div>
                  <div class="col-12 col-md-8">
                    {{ formatDate(damageReport.INFO.AST_SSD) }}
                  </div>
                </template>
                <template v-if="damageReport.INFO.AST_GUT_NAM">
                  <div class="col-12 col-md-4">
                    Gutachter
                  </div>
                  <div class="col-12 col-md-8">
                    {{ damageReport.INFO.AST_GUT_NAM }}
                  </div>
                </template>
              </div>
            </template>
          </ca-card>
          <ca-card class="mb-3">
            <template #header>
              <h4 class="mb-0">
                Forderung
              </h4>
            </template>
            <template #body>
              <damage-request-detail
                v-for="(payment, index) in damagePayment.data"
                :key="payment._id"
                :payment="payment"
                :index="index"
              />
            </template>
          </ca-card>
          <ca-card class="mb-3">
            <template #header>
              <h4 class="mb-0">
                Begründung
              </h4>
            </template>
            <template #body>
              <div
                v-for="(reason, index) in damageReport.ZAHLUNG.GRUND"
                :key="index"
                class="row mb-3"
              >
                <div class="col-12 col-md-4">
                  Grund {{ index + 1 }}
                </div>
                <div class="col-12 col-md-8">
                  <div
                    class="bg-gray-1 rounded p-3 mb-2"
                  >
                    <p v-html="reason" />
                  </div>
                </div>
              </div>
            </template>
          </ca-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import CaCard from '@/components/Card'
import formatDate from '@/filters/formatDate.js'
import damageType from '@/resources/enums/damage/damageType'
import contactType from '@/resources/enums/contactType'
import DamageRequestDetail from '@/components/Forms/DamageReport/DamageRequestDetail.vue'
import damageStatus from '@/resources/enums/damage/damageStatus'
import damageSendTo from '@/resources/enums/damage/damageSendTo'

export default {
  name: 'DamageReportDetail',
  components: {
    CaHeader,
    CaCard,
    DamageRequestDetail
  },
  data () {
    return {
      loading: false,
      damageReport: null,
      order: null,
      workshop: null,
      damagePayment: [],
      fields: [
        {
          label: 'Datum',
          key: ''
        }
      ],
      damageType,
      contactType,
      damageStatus,
      damageSendTo
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    formatDate,
    async fetchData () {
      try {
        this.loading = true
        this.damageReport = await this.$store.dispatch('damage-reports/get', this.$route.params.reportId)
        this.damagePayment = await this.$store.dispatch('damage-payments/find', {
          query: {
            reportId: this.damageReport._id
          }
        })
        this.order = await this.$store.dispatch('orders/get', this.damageReport.FIELDS.orderId)
        if (this.damageReport.FIELDS.workshopId) {
          this.workshop = await this.$store.dispatch('workshops/get', this.damageReport.FIELDS.workshopId)
        }
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

.site-header{
  z-index: 1020;
}

</style>
