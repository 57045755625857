<template>
  <!-- eslint-disable vue/no-v-html -->
  <b-card
    class="mb-2"
    no-body
    header
  >
    <template #header>
      <div
        class="row"
        @click="visible = !visible"
      >
        <div class="col d-flex align-items-center">
          <h6 class="mb-0">
            Zahlung {{ index + 1 }}
          </h6>
        </div>
        <div class="col-auto d-flex align-items-center">
          <div class="mr-3">
            {{ damagePayment[payment.Art].human }} / {{ formatDate(payment.ZahlDat, 'DD.MM.YYYY') }}
          </div>
          <button
            class="btn bg-transparent p-0"
          >
            <i
              class="far"
              :class="{'fa-chevron-down': visible, 'fa-chevron-left': !visible}"
            />
          </button>
        </div>
      </div>
    </template>
    <b-card-body :class="{'p-0': !visible}">
      <b-collapse
        id="zahlung"
        v-model="visible"
      >
        <div class="row mb-3">
          <div class="col-12 col-md-4">
            RG-Betrag
          </div>
          <div class="col-12 col-md-8">
            {{ payment.Zahlung }}€
          </div>
          <div class="col-12 col-md-4">
            Erstattung
          </div>
          <div class="col-12 col-md-8">
            {{ payment.ErstattungProzent }}%
          </div>
          <div class="col-12 col-md-4">
            Betrag abzgl. Erstattung
          </div>
          <div class="col-12 col-md-8">
            {{ payment.Zahlung - payment.Erstattung }}€
          </div>
          <div class="col-12 col-md-4">
            Zahlungsempfänger
          </div>
          <div class="col-12 col-md-8">
            {{ payment.An }}
          </div>
          <template v-if="payment.Bemerkung">
            <div class="col-12 col-md-4">
              Bemerkung
            </div>
            <div class="col-12 col-md-8">
              <div
                class="bg-gray-1 rounded p-3 mb-2"
              >
                <p v-html="payment.Bemerkung" />
              </div>
            </div>
          </template>
          <template v-if="payment.Status">
            <div class="col-12 col-md-4">
              Status
            </div>
            <div class="col-12 col-md-8">
              {{ damagePayStatus[payment.Status].human }}
            </div>
          </template>
        </div>
      </b-collapse>
    </b-card-body>
  </b-card>
</template>

<script>
import formatDate from '@/filters/formatDate.js'
import damagePayment from '@/resources/enums/damage/damagePayment'
import damagePayStatus from '@/resources/enums/damage/damagePayStatus'

export default {
  name: 'DamageRequestDetail',
  props: {
    payment: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      visible: false,
      damagePayment,
      damagePayStatus
    }
  },
  methods: {
    formatDate,
    ERSTBTRG (price, value) {
      return (price - (value / 100) * price).toFixed(2)
    }
  }
}
</script>
